import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Titil', // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description: 'Indie games and applications', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: 'Titil',
  name: 'Studio',
  subtitle: 'Games and Apps',
  cta: '',
};

// ABOUT DATA
export const aboutData = {
  title: 'About',
  img: 'titil_logo.png',
  paragraphOne:
    'Titil is a micro (single person) development studio, mostly focused on games, but also apps and software development tools.',
  paragraphTwo: ' ',
  paragraphThree: ' ',
  // resume: 'https://www.resumemaker.online/es.php', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'metronome_banner.png',
    title: 'Lucid Metronome',
    info:
      'Compact app with metronome functionality. Due to its small size, comsumes' +
      ' minimal amount of system resources. Can run in a background' +
      ' while other apps are being used or the phone screen goes offline.',
    info2:
      'The application itself is published to Google Play store.' +
      ' Both app and the source code are available for free.',
    url: 'https://play.google.com/store/apps/details?id=pl.titil.metronome',
    repo: 'https://github.com/xeelee/metronome',
  },
  {
    id: nanoid(),
    img: 'pico_platformer.png',
    title: 'Pico Platformer',
    info:
      'Set of tools for creating platform games for PICO-8 fantasy console.' +
      ' Consists of many helper functions, object factories and mechanisms,' +
      ' that can be composed into game skeleton without bothering about implementation' +
      ' of game physics, object movement, collision detection, etc.',
    info2:
      'There are 2 examples included. The first one presents all features and is published' +
      ' as a playable demo. The second one is a minimal example of player configuration on the' +
      ' level map. It can be a good starting point for implementation of new game.',
    url: 'https://titil.itch.io/pico-platformer',
    repo: 'https://github.com/xeelee/pico-platformer',
  },
  {
    id: nanoid(),
    img: 'matches_game1.png',
    title: 'Matches',
    info:
      'Simple logic game inspired by a popular puzzle.' +
      ' Player sees an equation which is initially incorrect.' +
      ' Their task is to pick one of the segments and place it at a proper position,' +
      ' so both sides of the equation match.',
    info2:
      'The game is released as a web page and mobile Android application as well.' +
      ' It is written in Haxe programming language (using HaxeFlixel game framework).' +
      ' Entire project was supposed to be a proof of concept,' +
      ' but after few improvements it became ready for release.',
    url: 'https://titil.itch.io/matches',
    repo: 'https://github.com/xeelee/matches', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta:
    'If you have any questions or need further information, simply email me by clicking button below.',
  email: 'rzeszut.michal@gmail.com',
};

// FOOTER DATA
export const footerData = {
  prefix: ' ',
  subject: 'Titil',
  link: 'https://github.com/xeelee/gatsby-simplefolio',
  networks: [
    {
      id: nanoid(),
      name: 'itch-io',
      url: 'https://titil.itch.io',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/xeelee',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
